import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import ContactForm from "../components/contactform"
import Countdown from "../components/countdown"

import Form from "../components/contactform"

const IndexPage = () => (
  <Layout>
    <SEO title="Kancelaria Radcy Prawnego Anny Beer" />
    <section className="top">
      <div className="wrap">
        <h2>
          Obsługa <br />
          prawna inwestycji
        </h2>
        <p>
          Doradzamy klientom w zakresie szeroko rozumianego procesu
          inwestycyjnego, począwszy od kompleksowej i stałej obsługi zamówień
          publicznych w trybie ustawy prawo zamówień publicznych. Posiadamy
          doświadczenie m.in. z zakresu prawa zamówień, prawa finansów
          publicznych oraz odpowiedzialności za naruszenie dyscypliny finansów
          publicznych.
        </p>
        <p>
          {/* <a href="" className="button inverted-button">
            Sprawdź naszą ofertę
          </a> */}
        </p>
      </div>
    </section>
    <section className="offer-highlights" id="oferta">
      <div className="offer-highlights-element immigration">
        <div className="overlay"></div>
        <div className="highlights-content">
          <h3>Zamówienia publiczne</h3>
          <p>
            Obsługujemy wszelkie procedury inwestycyjne w trybie prawa zamówień
            publicznych (również dofinansowane z funduszy unijnych), również w
            zakresie tzw. Umów partycypacyjnych (z deweloperami), czy
            partnerstwa publiczno-prywatnego
          </p>
        </div>
      </div>
      <div className="offer-highlights-element family">
        <div className="overlay"></div>
        <div className="highlights-content">
          <h3>Prawo budowlane</h3>
          <p>
            Na co dzień zajmujemy się kompleksową obsługą prawną największych
            inwestycji budowlanych na terenie województwa pomorskiego, z
            ramienia Wykonawców oraz Zamawiających, gdzie wielokrotnie łączna
            wartość Inwestycji kształtowała się / kształtuje się w okolicach
            kwot dziewięciocyfrowych.
          </p>
        </div>
      </div>
      <div className="offer-highlights-element corporate">
        <div className="overlay"></div>
        <div className="highlights-content">
          <h3>Ochrona środowiska</h3>
          <p>
            W ramach naszej praktyki, pomagamy naszym Klientom w uzyskaniu
            decyzji środowiskowych, reprezentujemy ich przed organami
            samorządowymi, państwowymi i unijnymi związanymi z ochroną
            środowiska naturalnego.
          </p>
        </div>
      </div>
    </section>
    <section class="why-us">
      <div className="wrap" id="o-kancelarii">
        <h5>Wiedza i doświadczenie</h5>
        <h2>Nasze mocne strony</h2>
        <hr />
        <p>
          Posiadamy wieloletnie doświadczenie w obsłudze prawnej jednostek
          sektora finansów publicznych (specjalizacja zamówienia publiczne) w
          tym – do chwili obecnej – kompleksowa obsługa zewnętrzna Dyrekcji
          Rozbudowy Miasta Gdańska – jednostka budżetowa Gminy Miasta Gdańsk,
          która organizuje i realizuje w imieniu i na rzecz Gminy oraz innych
          zamawiających na zasadzie porozumień (np. ENERGA – w tym zawieranie
          umów o przyłącze elektroenergetyczne GPEC, GIWK) wszelkie procedury
          inwestycyjne w trybie prawa zamówień publicznych (również
          dofinansowane z funduszy unijnych), również w zakresie tzw. Umów
          partycypacyjnych (z deweloperami), czy partnerstwa
          publiczno-prywatnego, w przedmiocie robót budowalnych (również na
          FIDICu, również w procedurze tzw. ZRIDu, głównie w modelu EPC) we
          wszystkich branżach, jak budowa dróg (np. Wiadukt Biskupia Górka, Nowa
          Bulońska, Nowa Warszawska), dostaw (np. dostawa sprzętów, wyposażenia,
          w tym sprzętu komputerowego) oraz usług, jak opracowywanie
          dokumentacji projektowej (np. w zakresie nabrzeży opływu Motławy),
          nadzory inwestorskie i autorskie oraz usługi informatyczne. Weryfikuję
          pod kątem formalnoprawnym i przeprowadzam postępowania w trybie ustawy
          prawo zamówień publicznych z wykorzystaniem platformy zakupowej Open
          Nexus.
        </p>
        <p>&nbsp;</p>
        <p>
          Prowadzimy obsługę prawną m.in. spółki należącej do grupy kapitałowej
          PKN Orlen S.A. tj. Baltic Power sp. z o.o. w zakresie projektu
          morskich farm wiatrowych na Morzu Bałtyckim,
        </p>
        <p>&nbsp;</p>
        <p>
          Uczestniczyliśmy w organizacji przetargów nieograniczonych i
          ograniczonych na rzecz PKP PLK S.A., z udziałem funduszy unijnych oraz
          krajowych, przygotowywaniu dokumentacji postępowania, prowadzeniu
          postępowania, analiza gwarancji i zabezpieczeń, procedowanie terminów
          związania ofert oraz roszczeń w ramach realizacji umów we wszelkich
          procedurach inwestycyjnych w zakresie linii kolejowych.
        </p>
      </div>
      <div className="wrap" id="zespol">
        <h5>Nasz zespół</h5>
        <h2>Ekspertów</h2>
        <hr />
        <p>
          Dysponujemy zespołem, który sprawia, że realizacja projektu staje się
          szybsza, bardziej przystępna i przewidywalna. Ponadto znajomość rynku
          lokalnego i miejscowych uwarunkowań, a także kluczowych aspektów,
          zagrożeń i wyzwań w projekcie stanowi realną wartość pozwalającą
          zakończyć projekt pełnym sukcesem.
        </p>
        <div className="why-us-boxes">
          <div className="why-us-box">
            <h5>Radca Prawny</h5>
            <h4>Anna Beer</h4>
          </div>
          <div className="why-us-box">
            <h5>Adwokat</h5>
            <h4>Wojciech Suszyński</h4>
          </div>
                   <div className="why-us-box">
            <h5>Radca Prawny</h5>
            <h4>Alicja Kornowicz</h4>
          </div>
          <div className="why-us-box">
            <h5>Aplikant Radcowski</h5>
            <h4>Małgorzata Michalska</h4>
          </div>
          <div className="why-us-box">
            <h5>Aplikant Radcowski</h5>
            <h4>Patrycja Czernuszewicz</h4>
          </div>
          <div className="why-us-box">
            <h5>Aplikant Radcowski</h5>
            <h4>Karolina Gorzeń</h4>
          </div>
        </div>
      </div>
    </section>
    <section className="home-contact" id="kontakt">
      <div className="home-contact-form">
        <div className="overlay"></div>
        <ContactForm />
      </div>
      <div className="home-contact-details"></div>
    </section>
  </Layout>
)

export default IndexPage
